<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title
          data-cy="transaction_create_title"
        >
          {{ $t('transaction.title') }}
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-container
      fill-height
      fluid
    >
      <v-tabs v-model="tab">
        <v-tab
          data-cy="new_tran_tab"
        >
          {{ $t('transaction.tabs.new') }}
        </v-tab>
        <v-tab
          data-cy="trans_hist_tab"
        >
          {{ $t('transaction.tabs.listing') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        style="width: 100%;"
      >
        <v-tab-item>
          <v-stepper
            v-model="step_onboard"
            vertical
          >
            <v-stepper-step
              step="1"
              :complete="!$v.form.$anyError && step_onboard > 1"
              :rules="[() => type == 'individual' ? !$v.form.$anyError : !$v.form_corp.$anyError]"
            >
              {{ $t('transaction.new.steps.client') }}
              <small v-show="$v.form.$anyError || $v.form_corp.$anyError">{{ $t('transaction.new.msg.client_error') }}</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-row v-if="existing.person || existing.company">
                <v-col cols="6">
                  <v-card-subtitle
                    data-cy="existing_client_title"
                  >
                    {{ $t('transaction.new.step1.subtitles.existing_client') }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-simple-table
                      data-cy="existing_client_table"
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            data-cy="client_type_row"
                          >
                            <td
                              class="px-0"
                              width="200px;"
                            >
                              {{ $t('transaction.new.step1.type.label') }}
                            </td>
                            <td v-if="existing.person">
                              {{ $t('transaction.new.step1.type.options.person') }}
                            </td>
                            <td v-else-if="existing.company">
                              {{ $t('transaction.new.step1.type.options.company') }}
                            </td>
                          </tr>
                          <tr
                            data-cy="client_name_row"
                          >
                            <td
                              class="px-0"
                              width="200px;"
                            >
                              {{ $t('transaction.new.step1.name.label') }}
                            </td>
                            <td v-if="existing.person">
                              {{ [existing.person.names[0].sur, existing.person.names[0].other].filter(Boolean).join(' ') }}
                            </td>
                            <td v-else-if="existing.company">
                              {{ existing.company.names[0].other }}
                            </td>
                          </tr>
                          <tr
                            data-cy="client_placeinfo_row"
                          >
                            <td
                              v-if="existing.person"
                              class="px-0"
                            >
                              {{ $t('person.details.form.pob.label') }}
                            </td>
                            <td v-if="existing.person">
                              {{ existing.person.pob || 'N/A' }}
                            </td>
                            <td
                              v-if="existing.company"
                              class="px-0"
                            >
                              {{ $t('company.details.form.incorp_place.label') }}
                            </td>
                            <td v-if="existing.company">
                              {{ existing.company.incorp_place || 'N/A' }}
                            </td>
                          </tr>
                          <tr
                            data-cy="client_dateinfo_row"
                          >
                            <td
                              v-if="existing.person"
                              class="px-0"
                            >
                              {{ $t('person.details.form.dob.label') }}
                            </td>
                            <td v-if="existing.person">
                              {{ (existing.person.dob ? moment(parseInt(existing.person.dob)).format('YYYY-MM-DD') : '') || 'N/A' }}
                            </td>
                            <td
                              v-if="existing.company"
                              class="px-0"
                            >
                              {{ $t('company.details.form.incorp_date.label') }}
                            </td>
                            <td v-if="existing.company">
                              {{ (existing.company.incorp_date ? moment(parseInt(existing.company.incorp_date)).format('YYYY-MM-DD') : '') || 'N/A' }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <small>{{ $t('transaction.details.client_type.label') }}</small><br>
                  <v-btn-toggle
                    v-model="type"
                    color="primary"
                    mandatory
                  >
                    <v-btn
                      :value="'individual'"
                      small
                      data-cy="new_indi_client_btn"
                    >
                      {{ $t('transaction.details.client_type.options.individual') }}
                    </v-btn>
                    <v-btn
                      :value="'corporate'"
                      small
                      data-cy="new_corp_client_btn"
                    >
                      {{ $t('transaction.details.client_type.options.corporate') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="6"
                  v-if="type == 'individual'"
                >
                  <v-menu
                    :offset-x="true"
                    :value="similar.person.names.is_show"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :open-on-click="false"
                  >
                    <template v-slot:activator="{ attrs }">
                      <v-row>
                        <v-col
                          cols="6"
                          class="py-0"
                        >
                          <v-text-field
                            :label="$t('person.details.form_name.sur.label')"
                            v-bind="attrs"
                            v-model="form.names[0].sur"
                            @focus="toggleSimilarDropdown(similar.person.names, true)"
                            @change="toggleSimilarDropdown(similar.person.names, form.names[0].sur.length > 0)"
                            @blur="toggleSimilarDropdown(similar.person.names, false)"
                            :error="$v.form.names.$each[0].sur.$error"
                            :error-messages="$v.form.names.$each[0].sur.$error ? (
                              !$v.form.names.$each[0].sur.required ? $t('validate.required', { field: $t('person.details.form_name.sur.label') }) : ''
                            ) : ''"
                            data-cy="person_surname_textfield"
                          />
                        </v-col>
                        <v-col
                          cols="6"
                          class="py-0"
                        >
                          <v-text-field
                            :label="$t('person.details.form_name.other.label')"
                            v-model="form.names[0].other"
                            @focus="toggleSimilarDropdown(similar.person.names, true)"
                            @change="toggleSimilarDropdown(similar.person.names, form.names[0].other.length > 0)"
                            @blur="toggleSimilarDropdown(similar.person.names, false)"
                            :error="$v.form.names.$each[0].other.$error"
                            :error-messages="$v.form.names.$each[0].sur.$error ? (
                              !$v.form.names.$each[0].other.required ? $t('validate.required', { field: $t('person.details.form_name.other.label') }) : ''
                            ) : ''"
                            data-cy="person_othername_textfield"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-card-subtitle
                        data-cy="exist_person_found_title"
                      >
                        {{ $t('person.details.form_name.dropdown.title') }}
                      </v-card-subtitle>
                      <v-card-text v-if="similar.person.names.options.length == 0">
                        {{ $t('person.details.form_name.dropdown.no_records') }}
                      </v-card-text>
                      <v-list v-else>
                        <v-list-item
                          v-for="(name, key) in similar.person.names.options"
                          @mousedown="() => {
                            existing.person = name.person
                            delete name.person
                            existing.person.names = [name];
                            similar.person.names.options.splice();
                          }"
                          :key="key"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              :data-cy="`person_${key}`"
                            >
                              {{ [name.sur, name.other].filter(Boolean).join(' ') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        label="HKID No."
                        v-model="form.idens[0].id_number"
                        :error="$v.form.idens.$each[0].id_number.$error"
                        :error-messages="($v.form.idens.$each[0].id_number.$dirty && $v.form.idens.$each[0].id_number.$error)?
                          (!$v.form.idens.$each[0].id_number.hkid_valid_format ? $t('person.details.form_iden.validate.hkid_valid_format') : (
                            !$v.form.idens.$each[0].id_number.hkid_checksum ? $t('person.details.form_iden.validate.hkid_checksum') : (
                              !$v.form.idens.$each[0].id_number.required ? $t('validate.required', { field: $t('person.details.form_iden.id_number.label') }) : ''
                            ))) : ''"
                        @change="() => {$v.form.idens.$touch()}"
                        data-cy="hkid_textfield"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <InputDate
                        v-model="form.dob"
                        :label="$t('person.details.form.dob.label')"
                        data-cy="person_dob"
                        ref="dob"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <CountrySelector
                        v-model="form.pob"
                        :label="$t('person.details.form.pob.label')"
                        data-cy="person_pob"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  v-else-if="type == 'corporate'"
                >
                  <v-row>
                    <v-col class="py-0">
                      <v-menu
                        :offset-x="true"
                        :value="similar.company.names.is_show"
                        :close-on-content-click="false"
                        :close-on-click="false"
                        :open-on-click="false"
                      >
                        <template v-slot:activator="{ attrs }">
                          <v-text-field
                            v-model="form_corp.names[0].other"
                            transition="scale-transition"
                            :label="$t('company.details.form_name.english.label')"
                            v-bind="{...attrs}"
                            @focus="toggleSimilarDropdown(similar.company.names, true)"
                            @change="toggleSimilarDropdown(similar.company.names, form_corp.names[0].other.length > 0)"
                            @blur="toggleSimilarDropdown(similar.company.names, false)"
                            :error="$v.form_corp.names.$anyError"
                            :error-messages="$v.form_corp.names.$anyError ?
                              (!$v.form_corp.names.$each[0].other.required ? $t('validate.required', { field: $t('company.details.form_name.english.label') }) : '') : ''"
                            data-cy="company_name_textfield"
                          />
                        </template>
                        <v-card>
                          <v-card-subtitle
                            data-cy="exist_company_found_title"
                          >
                            {{ $t('company.details.form_name.dropdown.title') }}
                          </v-card-subtitle>
                          <v-card-text
                            v-if="similar.company.names.options == 0"
                          >
                            {{ $t('company.details.form_name.dropdown.no_records') }}
                          </v-card-text>
                          <v-list v-else>
                            <v-list-item
                              v-for="(name, key) in similar.company.names.options"
                              @mousedown="() => {
                                existing.company = name.company
                                delete name.company;
                                existing.company.names = [name];
                                similar.company.names.options.splice(0);
                              }"
                              :key="key"
                            >
                              <v-list-item-content
                                :data-cy="`company_${key}`"
                              >
                                <v-list-item-title>{{ name.other }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-row>
                                    <v-col
                                      class="py-0"
                                      style="max-width:200px"
                                    >
                                      {{ $t('company.details.form.company_type.label') }}:
                                    </v-col>
                                    <v-col class="py-0">
                                      {{ name.company | company_type }}
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      class="py-0"
                                      style="max-width:200px"
                                    >
                                      {{ $t('company.details.form.coi_number.label') }}:
                                    </v-col>
                                    <v-col class="py-0">
                                      {{ name.company.coi_number }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-select
                        class="form-control"
                        v-model="form_corp.company_type"
                        :error="$v.form_corp.company_type.$error"
                        :error-messages="$v.form_corp.company_type.$error ? $t('validate.required', { field: $t('company.details.form.company_type.label') }) : ''"
                        :label="$t('company.details.form.company_type.label')"
                        clearable
                        :items="Object.values($t('company.details.form.company_type.options') || {}).map((v, k) => ({ value: k + 1, text: v }))"
                      >
                        <template
                          v-slot:selection="items"
                        >
                          <div
                            data-cy="selected_company_type"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                        <template
                          v-slot:item="items"
                        >
                          <div
                            :data-cy="`company_type_${items.item.value}`"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <country-selector
                        v-model="form_corp.incorp_place"
                        :label="$t('company.details.form.incorp_place.label')"
                        data-cy="incorp_place"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <InputDate
                        :label="$t('company.details.form.incorp_date.label')"
                        format="yyyy-MM-dd"
                        v-model="form_corp.incorp_date"
                        data-cy="incorp_date"
                        ref="incorp_date"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                class="mr-3"
                @click="validateAsync"
                :loading="step_1_loading"
                data-cy="tran_continue_btn"
              >
                {{ $t('transaction.new.buttons.continue') }}
              </v-btn>
              <v-btn
                text
                @click="onReset"
                :disabled="step_1_loading"
                data-cy="tran_reset_btn"
              >
                {{ $t('transaction.new.buttons.reset') }}
              </v-btn>
              <!-- <v-btn text @click="step_onboard = 2" :disabled="step_1_loading">debug</v-btn> -->
            </v-stepper-content>

            <v-stepper-step
              step="2"
              :rules="[() => !$v.form_trans.$anyError]"
            >
              {{ $t('transaction.new.steps.tran') }}
              <small v-if="$v.form_trans.$anyError">{{ $t('transaction.new.msg.tran_error') }}</small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <template v-if="form && results">
                <!-- <TransactionDetail ref="step2" :records="form_trans" @onSave="onReset" /> -->
                <v-row>
                  <v-col cols="5">
                    <v-simple-table
                      data-cy="client_info_table"
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td
                              class="px-0"
                              width="200px;"
                            >
                              {{ $t('transaction.new.step1.name.label') }}
                            </td>
                            <td v-if="type == 'individual'">
                              {{ [form.names[0].sur, form.names[0].other].filter(Boolean).join(' ') }}
                            </td>
                            <td v-else-if="type == 'corporate'">
                              {{ form_corp.names[0].other }}
                            </td>
                          </tr>
                          <tr>
                            <td class="px-0">
                              {{ type == 'individual' ? $t('person.details.form.pob.label') : $t('company.details.form.incorp_place.label') }}
                            </td>
                            <td v-if="type == 'individual'">
                              {{ form.pob || 'N/A' }}
                            </td>
                            <td v-else-if="type == 'corporate'">
                              {{ form_corp.incorp_place || 'N/A' }}
                            </td>
                          </tr>
                          <tr v-if="type == 'individual' && form.idens.length">
                            <td class="px-0">
                              HKID No.
                            </td>
                            <td>
                              {{ form.idens[0].id_number || 'N/A' }}
                            </td>
                          </tr>
                          <tr>
                            <td class="px-0">
                              {{ type == 'individual' ? $t('person.details.form.dob.label') : $t('company.details.form.incorp_date.label') }}
                            </td>
                            <td v-if="type == 'individual'">
                              <DateFormat
                                v-if="form.dob"
                                :date="form.dob"
                              />
                              <span v-else>N/A</span>
                            </td>
                            <td v-else-if="type == 'corporate'">
                              <DateFormat
                                v-if="form_corp.incorp_date"
                                :date="form_corp.incorp_date"
                              />
                              <span v-else>N/A</span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider />
                    <v-row>
                      <v-col cols="12">
                        <InputDate
                          v-model="form_trans.tran_date"
                          :label="$t('transaction.details.tran_date.label')"
                          :error="$v.form_trans.tran_date.$error"
                          :error-messages="!$v.form_trans.tran_date.required ? $t('validate.required', { field: $t('transaction.details.tran_date.label') }) : ''"
                          data-cy="tran_date"
                          ref="tran_date"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="form_trans.source_of_fund"
                          :label="$t('transaction.details.source_of_fund.label')"
                          data-cy="source_of_fund_textfield"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="form_trans.purpose"
                          :label="$t('transaction.details.purpose.label')"
                          data-cy="tran_purpose_textfield"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="form_trans.tran_ref"
                          :label="$t('transaction.details.tran_ref.label')"
                          data-cy="tran_ref_textfield"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <small
                          class="grey--text"
                          :class="$v.form_trans.tran_type.$anyError ? 'error--text' : ''"
                        >{{ $t('transaction.details.tran_type.label') }}</small><br>
                        <v-btn-toggle
                          v-model="form_trans.tran_type"
                          color="primary"
                          dense
                        >
                          <v-btn
                            :value="'forex'"
                            :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
                            data-cy="forex_btn"
                          >
                            {{ $t('transaction.details.tran_type.options[0]') }}
                          </v-btn>
                          <v-btn
                            :value="'inward'"
                            :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
                            data-cy="inward_btn"
                          >
                            {{ $t('transaction.details.tran_type.options[1]') }}
                          </v-btn>
                          <v-btn
                            :value="'outward'"
                            :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
                            data-cy="outward_btn"
                          >
                            {{ $t('transaction.details.tran_type.options[2]') }}
                          </v-btn>
                          <v-btn
                            :value="'loan'"
                            :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
                            data-cy="loan_btn"
                          >
                            {{ $t('transaction.details.tran_type.options[3]') }}
                          </v-btn>
                        </v-btn-toggle><br>
                        <small class="error--text">{{ $v.form_trans.tran_type.$anyError && !$v.form_trans.tran_type.required ? $t('validate.required', { field: $t('transaction.details.tran_type.label') }) : '' }}</small>
                      </v-col>
                      <v-col
                        cols="6"
                        v-if="['inward', 'outward'].indexOf(form_trans.tran_type) != -1"
                      >
                        <v-text-field
                          v-model="form_trans.recipient"
                          :label="$t('transaction.details.recipient.label')"
                          v-if="form_trans.tran_type == 'outward'"
                          data-cy="outward_recipient_textfield"
                        />
                        <v-text-field
                          v-model="form_trans.remitter"
                          :label="$t('transaction.details.remitter.label')"
                          v-else-if="form_trans.tran_type == 'inward'"
                          data-cy="inward_remitter_textfield"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        v-if="['inward', 'outward'].indexOf(form_trans.tran_type) != -1"
                      >
                        <CountrySelector
                          v-model="form_trans.counterparty_nationality"
                          :label="$t('transaction.details.counterparty_nationality.label')"
                          data-cy="counterparty_nationality"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          :label="$t('transaction.details.remarks.label')"
                          counter
                          auto-grow
                          rows="1"
                          row-height="15"
                          v-model="form_trans.remarks"
                          data-cy="remark_textarea"
                        />
                      </v-col>
                    </v-row>
                    <v-card
                      :color="tran_type_color"
                      dark
                      outlined
                      v-show="['forex', 'inward', 'outward', 'loan'].indexOf(form_trans.tran_type) != -1"
                    >
                      <v-card-text>
                        <v-card light>
                          <v-card-title
                            data-cy="tran_origin_title"
                          >
                            {{ $t('transaction.details.title_origin')[form_trans.tran_type] }}
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="6"
                                v-if="form_trans.tran_type != 'loan'"
                              >
                                <v-text-field
                                  v-model="form_trans.fund_in_channel"
                                  :label="$t('transaction.details.fund_in_channel.label')"
                                  data-cy="fund_in_channel_textfield"
                                />
                              </v-col>
                              <v-col
                                cols="6"
                                v-if="form_trans.tran_type != 'loan'"
                              >
                                <CountrySelector
                                  v-model="form_trans.country"
                                  :label="$t('transaction.details.country.label')"
                                  v-if="form_trans.tran_type != 'forex'"
                                  data-cy="fundin_country"
                                />
                              </v-col>
                              <v-col cols="6">
                                <InputCurrency
                                  v-model="form_trans.currency"
                                  :label="$t('transaction.details.currency.label')"
                                  data-cy="fundin_currency"
                                />
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="form_trans.amount"
                                  :label="$t('transaction.details.amount.label')"
                                  clearable
                                  placeholder="0.00"
                                  @keyup="(event) => {
                                    const match = this.form_trans.amount.match(/^\d+\.?(\d{0,2})?/)
                                    this.form_trans.amount = match ? match[0] : ''
                                  }"
                                  :hint="$t('transaction.details.notify.label')"
                                  :error="$v.form_trans.amount.$error"
                                  :error-messages="!$v.form_trans.amount.required ? $t('validate.required', { field: $t('transaction.details.amount.label') }) : ''"
                                  data-cy="fundin_amount"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-row v-if="form_trans.tran_type != 'loan'">
                          <v-col class="text-center">
                            <v-icon
                              size="35"
                              light
                            >
                              mdi-chevron-double-down
                            </v-icon>
                          </v-col>
                        </v-row>
                        <v-card
                          v-if="form_trans.tran_type != 'loan'"
                          light
                        >
                          <v-card-title
                            data-cy="tran_dest_title"
                          >
                            {{ $t('transaction.details.title_dest')[form_trans.tran_type] }}
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="form_trans.fund_out_channel"
                                  :label="$t('transaction.details.fund_out_channel.label')"
                                  data-cy="fund_out_channel_textfield"
                                />
                              </v-col>
                              <v-col cols="6">
                                <CountrySelector
                                  v-model="form_trans.country_to"
                                  :label="$t('transaction.details.country_to.label')"
                                  v-if="form_trans.tran_type != 'forex'"
                                  data-cy="fundout_country"
                                />
                              </v-col>
                              <v-col cols="6">
                                <InputCurrency
                                  v-model="form_trans.currency_to"
                                  :label="$t('transaction.details.currency_to.label')"
                                  data-cy="fundout_currency"
                                />
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="form_trans.amount_to"
                                  clearable
                                  :label="$t('transaction.details.amount_to.label')"
                                  placeholder="0.00"
                                  @keyup="(event) => {
                                    const match = this.form_trans.amount_to.match(/^\d+\.?(\d{0,2})?/)
                                    this.form_trans.amount_to = match ? match[0] : ''
                                  }"
                                  :hint="$t('transaction.details.notify.label')"
                                  :error="$v.form_trans.amount_to.$error"
                                  :error-messages="!$v.form_trans.amount_to.required ? $t('validate.required', { field: $t('transaction.details.amount_to.label') }) : ''"
                                  data-cy="fundout_amount"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="7">
                    <v-card class="ma-1">
                      <v-card-title
                        data-cy="client_screen_title"
                      >
                        {{ $t('transaction.details.title_screen') }}
                      </v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td
                                  class="px-0"
                                  width="200px;"
                                >
                                  {{ $t('transaction.details.hit.label') }}
                                </td>
                                <td :class="results.hit === 'Y' ? 'red--text': 'green--text'">
                                  <b>{{ results.hit === 'Y' ? $t('transaction.details.hit.options.yes') : $t('transaction.details.hit.options.no') }}</b>
                                </td>
                              </tr>
                              <tr>
                                <td class="px-0">
                                  {{ $t('alert.details.form.service_id.label') }}
                                </td>
                                <td>{{ results.scan_id }}</td>
                              </tr>
                              <tr>
                                <td class="px-0">
                                  {{ $t('screen.single.risk_level.label') }}
                                </td>
                                <td>{{ (type == 'individual' && form.pob) || (type == 'corporate' && form_corp.incorp_place) ? results.risk_level : $t('transaction.details.risk_level.na') }}</td>
                              </tr>
                              <tr>
                                <td class="px-0">
                                  {{ $t('screen.single.risk_score.label') }}
                                </td>
                                <td>{{ (type == 'individual' && form.pob) || (type == 'corporate' && form_corp.incorp_place) ? results.risk_score : $t('transaction.details.risk_level.na') }}</td>
                              </tr>
                              <tr v-if="results.hit === 'Y' ">
                                <td class="px-0">
                                  {{ $t('alert.details.buttons.view_details') }}
                                </td>
                                <td class="pa-2">
                                  <v-dialog>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        small
                                        text
                                        color="primary"
                                        data-cy="view_screening_detail_btn"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon left>
                                          mdi-open-in-new
                                        </v-icon>
                                        {{ `${$t('alert.details.buttons.view_details')} (${results.details.hitDetails.length})` }}
                                      </v-btn>
                                    </template>
                                    <template v-slot:default="dialog">
                                      <v-card>
                                        <v-card-title>
                                          Screening Details
                                          <v-spacer />
                                          <v-btn
                                            outlined
                                            @click="dialog.value = false"
                                          >
                                            Close
                                          </v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                          <ScreeningResultsTableCreation
                                            v-if="results"
                                            :scan-detail="results"
                                            :entity-detail-form="formAlertCase"
                                            @update="onAlertCaseUpdate"
                                          />
                                        </v-card-text>
                                      </v-card>
                                    </template>
                                  </v-dialog>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <v-btn
                color="primary"
                class="mr-3"
                @click="onComplete"
                :loading="step_2_loading"
                data-cy="save_all_btn"
              >
                {{ $t('transaction.new.buttons.save') }}
              </v-btn>
              <v-btn
                color="primary"
                class="mr-3"
                @click="onTakeScreenshot"
                :loading="is_taking_screenshot"
              >
                <v-icon left>
                  mdi-monitor-screenshot
                </v-icon>
                {{ $t('transaction.new.buttons.screenshot') }}
              </v-btn>
              <v-btn
                text
                @click="onReset"
                :disabled="step_2_loading"
                data-cy="cancel_btn"
              >
                {{ $t('transaction.new.buttons.cancel') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-tab-item>
        <v-tab-item>
          <loading
            :active.sync="requestLoading"
            :is-full-page="false"
          />
          <v-server-table
            ref="table"
            name="transaction/transactionTable"
            :columns="[
              'tran_ref',
              'tran_date',
              'names',
              'currency',
              'amount',
              'case_id',
              'case_status',
              'action'
            ]"
            :options="{
              ...options,
              headings: {
                names: $t('transaction.listing.name.label'),
                tran_ref: $t('transaction.details.tran_ref.label'),
                tran_date: $t('transaction.details.tran_date.label'),
                currency: $t('transaction.details.currency.label'),
                amount: $t('transaction.details.amount.label'),
                case_id: $t('transaction.details.alert_case_id.label'),
                case_status: $t('transaction.details.alert_case_status.label'),
                action: $t('listing.table.action.label')
              }
            }"
          >
            <div
              slot="names"
              slot-scope="props"
            >
              <router-link
                color="primary"
                :to="{ name: 'NaturalPersonEdit', params: { np_id: props.row.person.id } }"
                v-if="props.row.person"
                :data-cy="`to_NaturalPersonEdit_${props.index}`"
              >
                <div>{{ props.row.person | natural_person_name(1, 1) }}</div>
                <div>{{ props.row.person | natural_person_name(2, 1) }}</div>
              </router-link>
              <router-link
                color="primary"
                :to="{ name: 'CompanyEdit', params: { customer_id: props.row.company.id } }"
                v-else-if="props.row.company"
                :data-cy="`to_CompanyEdit_${props.index}`"
              >
                <div>{{ props.row.company | company_name(1) }}</div>
                <div>{{ props.row.company | company_name(2) }}</div>
              </router-link>
              <span v-else>(Deleted)</span>
            </div>
            <div
              slot="tran_date"
              slot-scope="props"
            >
              <DateFormat
                :date="props.row.tran_date"
                :data-cy="`tran_date_${props.index}`"
              />
              <!-- <span v-tooltip="{ content: props.row.tran_date ? new Date(props.row.tran_date) : ''}" v-if="props.row.tran_date">
								<timeago :datetime="props.row.tran_date" :auto-update="60"></timeago>
							</span> -->
            </div>
            <div
              slot="amount"
              slot-scope="props"
            >
              {{ (parseFloat(props.row.amount)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            </div>
            <div
              slot="case_id"
              slot-scope="props"
            >
              <v-btn
                v-if="props.row.alert_case"
                text
                color="primary"
                @click="() => {
                  dialog_alert_case_detail = true
                  dialog_alert_case_id = props.row.alert_case.id
                }"
              >
                {{ props.row.alert_case.id }}
              </v-btn>
              <span
                v-else
                class="px-4"
              >
                N/A
              </span>
            </div>
            <div
              slot="case_status"
              slot-scope="props"
            >
              <v-btn
                v-if="props.row.alert_case"
                text
                color="black"
                depressed
                @click="() => {
                  dialog_alert_case_detail = true
                  dialog_alert_case_id = props.row.alert_case.id
                }"
              >
                {{ $t('alert_case.table.header.status.options')[props.row.alert_case.status] }}
              </v-btn>
              <span
                v-else
                class="px-4"
              >
                N/A
              </span>
            </div>
            <div
              slot="action"
              slot-scope="props"
            >
              <v-btn
                icon
                @click="dialog_tran_detail_record = props.row; dialog_tran_detail = true;"
                :data-cy="`edit_tran_btn_${props.index}`"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <PopupDelete
                v-slot:default="{ on, loading }"
                @yes="(done) => {
                  $store.dispatch('transaction/remove', props.row.id)
                    .then(() => {
                      $refs.table.getData();
                    })
                    .catch((error) => { console.warn(error) })
                    .finally(done); // notify popup stop loading and close
                }"
                :data-cy="`tran_${props.index}`"
              >
                <v-btn
                  icon
                  class="mr-1"
                  color="error"
                  v-on="on"
                  :loading="loading"
                  :data-cy="`remove_tran_btn_${props.index}`"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </PopupDelete>
            </div>
          </v-server-table>
          <v-dialog
            v-model="dialog_tran_detail"
            max-width="90%"
          >
            <v-card>
              <PopupTranDetail
                :records="dialog_tran_detail_record"
                @onSave="(form) => {
                  $refs.table.getData();
                  dialog_tran_detail = false;
                  dialog_tran_detail_record = null;
                }"
              />
            </v-card>
          </v-dialog>
          <!-- <v-dialog
            v-model="dialog_alert_detail"
            max-width="90%"
          >
            <v-card>
              <PopupAlertDetail
                :records="dialog_alert_detail_record"
                @onSave="(form) => {
                  $refs.table.getData();
                  dialog_alert_detail = false;
                  dialog_alert_detail_record = null;
                }"
                data-cy="alert_detail"
              />
            </v-card>
          </v-dialog> -->
          <v-dialog
            v-model="dialog_alert_case_detail"
          >
            <AlertCaseDetailsContent :case-id="dialog_alert_case_id" />
          </v-dialog>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-card>
</template>

<script>
/* global error */
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'

// .vue
// import Loading from 'vue-loading-overlay'
import InputCurrency from '@/components/forms/InputCurrency'
import CountrySelector from '@/components/forms/CountrySelector'
import InputDate from '@/components/forms/InputDate'
import PopupTranDetail from '@/components/popups/PopupTranDetail2'
// import PopupAlertDetail from '@/components/popups/PopupAlertDetail'
// import TransactionDetail from '@/components/forms/TransactionDetail'
import DateFormat from '@/components/common/DateFormat'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'
import html2canvas from 'html2canvas'
import ScreeningResultsTableCreation from '@/components/common/ScreeningResultsTableCreation'
import AlertCaseDetailsContent from '../forms/AlertCaseDetailsContent'

const customCurrencyValidation = (value) => String(value).match(/^\d+\.?(?:\d+)?$/) != null

const default_form = {
  form: () => ({
    names: [
      {
        name_type: 1,
        lang: 1,
        other: '',
        sur: ''
        // effect_date: null,
        // create_date: null,
        // updated_date: null
      }
    ],
    idens: [
      {
        type: 1,
        country: '',
        id_number: '',
        id_number_security_num: '',
        expiry_date: null,
        expiry_date_security_num: '',
        effect_date: null
        // create_date: null,
        // updated_date: null,
      }
    ],
    dob: '',
    pob: ''
  }),
  form_corp: () => ({
    company_type: '',
    names: [
      {
        name_type: 3,
        lang: 1,
        other: '',
        sur: ''
      }
    ],
    incorp_place: '',
    incorp_date: ''
  }),
  // form_alert: () => ({
  //   hit_status: 0,
  //   exposition: '',
  //   remarks: ''
  // }),
  form_trans: () => ({
    tran_date: (new Date()).getTime(),
    tran_type: '',
    amount: '0.00',
    currency: 'HKD',
    fund_in_channel: '',
    country: '',
    amount_to: '0.00',
    currency_to: 'USD',
    fund_out_channel: '',
    country_to: '',
    source_of_fund: '',
    purpose: '',
    tran_ref: '',
    counterparty_nationality: '',
    recipient: '',
    remitter: '',
    remarks: ''
  })
}
export default {
  components: {
    // Loading,
    InputCurrency,
    InputDate,
    CountrySelector,
    PopupTranDetail,
    // PopupAlertDetail,
    DateFormat,
    PopupDelete,
    ScreeningResultsTableCreation,
    AlertCaseDetailsContent
  },
  watch: {
    'form.names': {
      deep: true,
      handler (names) {
        var name = names[0]
        clearTimeout(this.name_similar_check_timeout)
        if (!name.other && !name.sur) {
          this.similar.person.names.options = []
          return this.similar.person.names.options
        }

        this.name_similar_check_timeout = setTimeout(() => {
          this.$store.dispatch('natural_person/similarName', {
            person_id: 0, // ignore person id filter
            lang: name.lang,
            name_type: name.name_type,
            sur: name.sur,
            other: name.other
          })
            .then((results) => {
              if (results.data) { this.similar.person.names.options = results.data.filter(x => x.person != null) }
            })
        }, 500)
      }
    },
    'form_corp.names': {
      deep: true,
      handler (names) {
        var name = names[0]
        clearTimeout(this.name_similar_check_timeout)
        if (!name.other) {
          this.similar.company.names.options = []
          return this.similar.company.names.options
        }

        this.name_similar_check_timeout = setTimeout(() => {
          this.$store.dispatch('company/similarName', {
            company_id: 0,
            lang: name.lang,
            company_name: name.other
          })
            .then((results) => {
              if (results.data) { this.similar.company.names.options = results.data }
            })
        }, 500)
      }
    }
  },
  computed: {
    ...mapGetters('alerts', [
      'hit_status_options',
      'exposition_options',
      'suggested_remarks'
    ]),
    ...mapGetters('transaction', [
      'options'
    ]),
    ...mapState('transaction', {
      requestLoading: state => state.listing.requestLoading,
      columns: state => state.listing.columns,
      dateColumns: state => state.listing.dateColumns
      // options: state => state.listing.options
    }),
    tran_type_color () {
      switch (this.form_trans.tran_type) {
        case 'forex':
          return 'pink lighten-5'
        case 'inward':
          return 'deep-purple lighten-5'
        case 'outward':
          return 'indigo lighten-5'
        case 'loan':
          return 'blue lighten-5'
        default:
          return ''
      }
    }
  },
  data () {
    return {
      step_onboard: 1,
      step_1_loading: false,
      step_2_loading: false,
      type: 'individual',
      form: default_form.form(),
      form_corp: default_form.form_corp(),
      // form_alert: default_form.form_alert(),
      form_trans: default_form.form_trans(),
      results: null,
      loadingWizard: false,
      // errorMsg: null,
      person: null,
      company: null,
      // alert: null,
      tab: null,
      step: null,
      dialog_tran_detail: false,
      dialog_tran_detail_record: null,
      // dialog_alert_detail: false,
      // dialog_alert_detail_record: null,
      similar: {
        person: {
          names: {
            options: [],
            is_show: false
          },
          idens: {
            options: [],
            is_show: false
          }
        },
        company: {
          names: {
            options: [],
            is_show: false
          }
        }
      },
      name_similar_check_timeout: null,
      iden_similar_check_timeout: null,
      existing: {
        person: null,
        company: null
      },
      is_taking_screenshot: false,
      alert_case_entities: [],
      dialog_alert_case_detail: false,
      dialog_alert_case_id: null,
      formAlertCase: {}
    }
  },
  validations () {
    return {
      type: required,
      form: {
        names: {
          $each: {
            sur: {
              required: requiredIf(() => !this.form.names[0].other)
            },
            other: {
              required: requiredIf(() => !this.form.names[0].sur)
            }
          }
        },
        idens: {
          $each: {
            id_number: {
              // required,
              hkid_valid_format (hkid_num) {
                if (hkid_num === '' || this.form.idens[0].type !== 1) {
                  return true
                }

                return new Promise((resolve) => {
                  // var match = hkid_num.match(/^[A-Za-z]+\d{6}\(?\d{1}\)?$/);
                  // resolve(match && match.length)
                  var match = hkid_num.toUpperCase().match(/([A-Z]{1,2})(\d)(\d)(\d)(\d)(\d)(\d)\(?([\dA])\)?/)
                  resolve(match && match.length)
                })
              },
              hkid_checksum (id_number) {
                if (id_number === '' || this.form.idens[0].type !== 1) return true
                return new Promise((resolve) => {
                  var hkids = id_number.toUpperCase().match(/([A-Z]{1,2})(\d)(\d)(\d)(\d)(\d)(\d)\(?([\dA])\)?/)
                  var valid
                  if (hkids) {
                    var sum = 0
                    sum = sum + 2 * hkids[7]
                    sum = sum + 3 * hkids[6]
                    sum = sum + 4 * hkids[5]
                    sum = sum + 5 * hkids[4]
                    sum = sum + 6 * hkids[3]
                    sum = sum + 7 * hkids[2]
                    sum = sum + 8 * (hkids[1].charCodeAt(hkids[1].length === 2 ? 1 : 0) - 55)
                    sum = sum + 9 * (hkids[1].length === 2 ? (hkids[1].charCodeAt(0) - 55) : 58)
                    var checkdigit = sum % 11
                    valid = '0A987654321'.indexOf(hkids[8]) === checkdigit
                  } else {
                    valid = false
                  }

                  resolve(valid)
                })
              }
            }
          }
        }
        // name: {
        // 	required
        // }
      },
      form_corp: {
        names: {
          $each: {
            other: {
              required
            }
          }
        },
        company_type: {
          required
        }
      },
      // form_alert: {
      //   exposition: {
      //     required: requiredIf(() => this.alert || this.form_alert.hit_status === 1 || this.form_alert.hit_status === 2)
      //   },
      //   remarks: {
      //     required: requiredIf(() => this.alert || this.form_alert.hit_status === 1 || this.form_alert.hit_status === 2)
      //   }
      // },
      form_trans: {
        amount: {
          required,
          customCurrencyValidation
        },
        amount_to: {
          required: requiredIf(() => ['forex', 'inward', 'outward'].indexOf((this.form_trans.tran_type || '').toLowerCase()) !== -1),
          customCurrencyValidation
        },
        tran_date: {
          required
        },
        tran_type: {
          required
        }
      }
    }
  },
  methods: {
    async onComplete () {
      // this.$v.form_alert.$touch()
      this.$v.form_trans.$touch()
      if (/* this.$v.form_alert.$invalid || */ this.$v.form_trans.$invalid || !this.checkAllDateFormat()) { return }
      this.step_2_loading = true

      // create client record and alert case(if needed)
      // Save Person
      if (this.type === 'individual') {
        if (this.existing.person) {
          var form_results = await this.$store.dispatch('natural_person/findOne', this.existing.person.id)
          this.person = form_results.data
        } else {
          form_results = await this.$store.dispatch('natural_person/save', {
            status: 1, // default active
            pob: this.form.pob || '',
            dob: this.form.dob || '',
            names: this.form.names || [],
            idens: String(this.form.idens[0].id_number).length > 0 ? this.form.idens : [] // if HKID not empty
          })
          if (form_results.status === 200) {
            this.person = form_results.data
          } else { return }
        }
      } else if (this.type === 'corporate') {
        if (this.existing.company) {
          form_results = await this.$store.dispatch('company/findOne', this.existing.company.id)
          this.company = form_results.data
        } else {
          form_results = await this.$store.dispatch('company/save', {
            status: 1, // default active
            incorp_place: this.form_corp.incorp_place || '',
            incorp_date: this.form_corp.incorp_date || '',
            company_type: this.form_corp.company_type,
            names: this.form_corp.names || []
            // idens: String(this.form.idens[0].id_number).length > 0 ? this.form.idens : [] // if HKID not empty
          })
          if (form_results.status === 200) {
            this.company = form_results.data
          } else { return }
        }
      }

      // Save Alert Case
      if (this.results) {
        const alert_case_entities = await this.$store.dispatch('alert_case/createEntities', {
          review_token: this.results.review_token,
          [this.type === 'individual' ? 'person' : 'company']: this[this.type === 'individual' ? 'person' : 'company'].id,
          decisions: Object.keys(this.formAlertCase).map(key => {
            return {
              entity_id: key,
              ...this.formAlertCase[key]
            }
          })
        })
        if (alert_case_entities && alert_case_entities.status === 200) {
          this.alert_case_entities = alert_case_entities.data
        }
      }

      // Save Transaction
      var transaction = JSON.parse(JSON.stringify(this.form_trans))
      var dateStringToInt = (date_string) => {
        date_string = date_string || 0 // default to 0
        return typeof date_string === 'string' && date_string && !isNaN(new Date(date_string)) ? new Date(date_string).getTime() : date_string
      }

      if (this.type === 'individual') { transaction.person = this.person.id } else { transaction.company = this.company.id }
      console.log(this.alert_case_entities)
      if (this.alert_case_entities.length > 0) { transaction.alert_case = this.alert_case_entities[0].alert_case }
      transaction.tran_date = dateStringToInt(transaction.tran_date)
      // if (this.alert) { transaction.alert = this.alert.id }
      this.loadingWizard = true
      var form_tran_results = await this.$store.dispatch('transaction/save', transaction)
      this.loadingWizard = false
      // this.form = default_form.form();
      // this.form_alert = default_form.form_alert();
      // this.form_trans = default_form.form_trans();
      // this.$refs['onboard_form'].reset();
      this.onReset()
      if (this.$refs.table) { this.$refs.table.getData() }
      if (form_tran_results.status === 200) {
        return this.$notify({
          type: 'success',
          title: this.$t('transaction.details.msg.save.title'),
          text: this.$t('transaction.details.msg.save.success')
        })
      } else {
        this.$notify({
          type: 'error',
          title: this.$t('transaction.details.msg.save.title'),
          text: error.message
        })
      }
    },
    onReset () {
      // this.loadingWizard = false;
      this.form = default_form.form()
      this.form_corp = default_form.form_corp()
      // this.form_alert = default_form.form_alert()
      this.form_trans = default_form.form_trans()
      // this.$refs['onboard_form'].reset();
      this.$v.form.$reset()
      this.$v.form_corp.$reset()
      // this.$v.form_alert.$reset()
      this.$v.form_trans.$reset()
      this.step_onboard = 1
      this.step_1_loading = false
      this.step_2_loading = false
      this.existing.person = null
      this.existing.company = null
      this.similar.person.names.options.splice(0)
      this.similar.person.names.is_show = false
      this.similar.person.idens.options.splice(0)
      this.similar.person.idens.is_show = false
      this.similar.company.names.options.splice(0)
      this.similar.company.names.is_show = false
      this.formAlertCase = {}
    },
    checkAllDateFormat () {
      let correctDate = true
      if (this.step_onboard === 1) {
        if (this.type === 'individual') {
          correctDate = (this.$refs.dob || { correctDate: true }).correctDate
        } else if (this.type === 'corporate') {
          correctDate = (this.$refs.incorp_date || { correctDate: true }).correctDate
        }
      } else if (this.step_onboard === 2) {
        correctDate = correctDate && (this.$refs.tran_date || { correctDate: true }).correctDate
      }

      return correctDate
    },
    async validateAsync () {
      this.step_1_loading = true
      let screening_options = null
      if (this.existing.person) {
        this.form.names = this.existing.person.names
        // this.form.idens = this.existing.person.idens
        this.form.idens = (await this.$client.identification.findWhere({
          where: {
            type: 1, // HKID
            parent: this.existing.person.id
          }
        })).data
        this.form.dob = this.existing.person.dob
        this.form.pob = this.existing.person.pob
        screening_options = {
          lastScreenAt: new Date(this.existing.person.batch_scan_date).toISOString()
        }
      } else if (this.existing.company) {
        this.form_corp.names[0] = this.existing.company.names[0]
        this.form_corp.incorp_date = this.existing.company.incorp_date
        this.form_corp.incorp_place = this.existing.company.incorp_place
        this.form_corp.company_type = this.existing.company.company_type
        screening_options = {
          lastScreenAt: new Date(this.existing.company.batch_scan_date).toISOString()
        }
      }
      if (this.type === 'individual') {
        this.$v.form.$reset()
        this.$v.form.$touch()

        if (this.$v.form.$anyError || !this.checkAllDateFormat()) {
          this.step_1_loading = false
          return
        }
        screening_options = {
          ...screening_options,
          entityType: 'Individual',
          name: [this.form.names[0].sur, this.form.names[0].other].filter(Boolean).join(' '),
          countries: [this.form.pob] || [],
          yearOfBirth: (this.form.dob ? (new Date(this.form.dob).getFullYear()) : null) || null
        }
      } else if (this.type === 'corporate') {
        this.$v.form_corp.$reset()
        this.$v.form_corp.$touch()

        if (this.$v.form_corp.$anyError || !this.checkAllDateFormat()) {
          this.step_1_loading = false
          return
        }
        screening_options = {
          ...screening_options,
          entityType: 'Corporate',
          name: this.form_corp.names[0].other,
          countries: [this.form_corp.incorp_place] || []
        }
      }

      this.$store.dispatch('scan/singleScreeningEWE', {
        ...screening_options,
        minConfidenceScore: 30
      })
        .then(async (results) => {
          if (results.status === 200) {
            // store screening results
            this.results = results.data
            this.results.details.hitDetails.forEach((hitDetail) => {
              this.formAlertCase[hitDetail.entityID] = {
                status: 0,
                exposition: '',
                remarks: ''
              }
            })
            // go to step 2
            this.step_onboard = 2
          }
        })
        .finally(() => {
          this.step_1_loading = false
        })
    },
    tabClicked (selectedTab) {
      this.tab = selectedTab.tab.name
    },
    toggleSimilarDropdown (field, is_show) {
      clearTimeout(field.timeout)
      setTimeout(() => {
        field.is_show = is_show
      }, 200)
    },
    onTakeScreenshot () {
      this.is_taking_screenshot = true
      document.querySelector('#app').classList.add('html2canvas')
      html2canvas(document.querySelector('#app'), {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight
      }).then(function (canvas) {
        var link = document.createElement('a')
        link.download = 'transaction.png'
        link.href = canvas.toDataURL('image/png')
        link.click()
      })
        .catch(() => {

        })
        .finally(() => {
          document.querySelector('#app').classList.remove('html2canvas')
          this.is_taking_screenshot = false
        })
    },
    onAlertCaseUpdate (newFormAlertCase) {
      console.log('fking', newFormAlertCase)
      Object.assign(this.formAlertCase, newFormAlertCase)
      console.log(this.formAlertCase)
    }
  }
  // mounted () {
  //   console.log(this)
  // }
}
</script>

<style>
</style>
