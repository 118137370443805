<template>
  <v-row v-if="records">
    <loading
      :active.sync="requestLoading"
      :is-full-page="false"
    />
    <v-col cols="6">
      <v-simple-table v-if="records.person || records.company">
        <template v-slot:default>
          <tbody>
            <tr>
              <td
                class="px-0"
                width="200px;"
              >
                {{ $t('transaction.listing.name.label') }}
              </td>
              <td>{{ records.person ? [records.person.names[0].sur, records.person.names[0].other].filter(Boolean).join(' ') : records.company.names[0].other }}</td>
            </tr>
            <tr>
              <td class="px-0">
                {{ records.person ? $t('person.details.form.pob.label') : $t('company.details.form.incorp_place.label') }}
              </td>
              <td>{{ records.person ? records.person.pob : records.company.incorp_place || 'N/A' }}</td>
            </tr>
            <tr v-if="records.person">
              <td class="px-0">
                HKID No.
              </td>
              <td>
                {{ records.person.idens && records.person.idens.find(iden => iden.type == 1) ? records.person.idens.find(iden => iden.type == 1).id_number : 'N/A' }}
              </td>
            </tr>
            <tr>
              <td class="px-0">
                {{ records.person ? $t('person.details.form.dob.label') : $t('company.details.form.incorp_date.label') }}
              </td>
              <!-- <td>{{ records.person ? (records.person.dob ? new Date(parseInt(records.person.dob)) : '') : (records.company.incorp_date ? new Date(parseInt(records.person.dob)) : '') || 'N/A' }}</td> -->
              <td v-if="records.person">
                <DateFormat
                  v-if="records.person.dob"
                  :date="records.person.dob"
                />
                <span v-else>N/A</span>
              </td>
              <td v-else-if="records.company">
                <DateFormat
                  v-if="records.company.incorp_date"
                  :date="records.company.incorp_date"
                />
                <span v-else>N/A</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <span v-else>Record Deleted</span>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <InputDate
            v-model="form_trans.tran_date"
            :label="$t('transaction.details.tran_date.label')"
            :error="$v.form_trans.tran_date.$error"
            :error-messages="!$v.form_trans.tran_date.required ? $t('validate.required', { field: $t('transaction.details.tran_date.label') }) : ''"
            data-cy="tran_date"
            ref="tran_date"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form_trans.source_of_fund"
            :label="$t('transaction.details.source_of_fund.label')"
            data-cy="source_of_fund_textfield"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form_trans.purpose"
            :label="$t('transaction.details.purpose.label')"
            data-cy="tran_purpose_textfield"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form_trans.tran_ref"
            :label="$t('transaction.details.tran_ref.label')"
            data-cy="tran_ref_textfield"
          />
        </v-col>
        <v-col
          cols="12"
          class="pt-0"
        >
          <small
            class="grey--text"
            :class="$v.form_trans.tran_type.$anyError ? 'error--text' : ''"
          >{{ $t('transaction.details.tran_type.label') }}</small><br>
          <v-btn-toggle
            v-model="form_trans.tran_type"
            color="primary"
            dense
          >
            <v-btn
              :value="'forex'"
              :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
              data-cy="forex_btn"
            >
              {{ $t('transaction.details.tran_type.options[0]') }}
            </v-btn>
            <v-btn
              :value="'inward'"
              :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
              data-cy="inward_btn"
            >
              {{ $t('transaction.details.tran_type.options[1]') }}
            </v-btn>
            <v-btn
              :value="'outward'"
              :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
              data-cy="outward_btn"
            >
              {{ $t('transaction.details.tran_type.options[2]') }}
            </v-btn>
            <v-btn
              :value="'loan'"
              :color="$v.form_trans.tran_type.$anyError ? 'error' : ''"
              data-cy="loan_btn"
            >
              {{ $t('transaction.details.tran_type.options[3]') }}
            </v-btn>
          </v-btn-toggle><br>
          <small class="error--text">{{ $v.form_trans.tran_type.$anyError && !$v.form_trans.tran_type.required ? $t('validate.required', { field: $t('transaction.details.tran_type.label') }) : '' }}</small>
        </v-col>
        <v-col
          cols="6"
          v-if="['inward', 'outward'].indexOf(form_trans.tran_type) != -1"
        >
          <v-text-field
            v-model="form_trans.recipient"
            :label="$t('transaction.details.recipient.label')"
            v-if="form_trans.tran_type == 'outward'"
            data-cy="outward_recipient_textfield"
          />
          <v-text-field
            v-model="form_trans.remitter"
            :label="$t('transaction.details.remitter.label')"
            v-else-if="form_trans.tran_type == 'inward'"
            data-cy="inward_remitter_textfield"
          />
        </v-col>
        <v-col
          cols="6"
          v-if="['inward', 'outward'].indexOf(form_trans.tran_type) != -1"
        >
          <CountrySelector
            v-model="form_trans.counterparty_nationality"
            :label="$t('transaction.details.counterparty_nationality.label')"
            data-cy="counterparty_nationality"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            :label="$t('transaction.details.remarks.label')"
            counter
            auto-grow
            rows="1"
            row-height="15"
            v-model="form_trans.remarks"
            data-cy="remark_textarea"
          />
        </v-col>
      </v-row>
      <v-card
        :color="tran_type_color"
        dark
        outlined
        v-show="['forex', 'inward', 'outward', 'loan'].indexOf(form_trans.tran_type) != -1"
      >
        <v-card-text>
          <v-card light>
            <v-card-title
              data-cy="tran_origin_title"
            >
              {{ $t('transaction.details.title_origin')[form_trans.tran_type] }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  v-if="form_trans.tran_type != 'loan'"
                >
                  <v-text-field
                    v-model="form_trans.fund_in_channel"
                    :label="$t('transaction.details.fund_in_channel.label')"
                    data-cy="fund_in_channel_textfield"
                  />
                </v-col>
                <v-col
                  cols="6"
                  v-if="form_trans.tran_type != 'loan'"
                >
                  <CountrySelector
                    v-model="form_trans.country"
                    :label="$t('transaction.details.country.label')"
                    data-cy="fundin_country"
                  />
                </v-col>
                <v-col cols="5">
                  <InputCurrency
                    v-model="form_trans.currency"
                    :label="$t('transaction.details.currency.label')"
                    data-cy="fundin_currency"
                  />
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    :label="$t('transaction.details.amount.label')"
                    v-model="form_trans.amount"
                    clearable
                    placeholder="0.00"
                    @keyup="(event) => {
                      const match = this.form_trans.amount.match(/^\d+\.?(\d{0,2})?/)
                      this.form_trans.amount = match ? match[0] : ''
                    }"
                    :hint="$t('transaction.details.notify.label')"
                    :error="$v.form_trans.amount.$error"
                    :error-messages="!$v.form_trans.amount.required ? $t('validate.required', { field: $t('transaction.details.amount.label') }) : ''"
                    data-cy="fundin_amount"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row v-if="form_trans.tran_type != 'loan'">
            <v-col class="text-center">
              <v-icon
                size="35"
                light
              >
                mdi-chevron-double-down
              </v-icon>
            </v-col>
          </v-row>
          <v-card
            v-if="form_trans.tran_type != 'loan'"
            light
          >
            <v-card-title
              data-cy="tran_dest_title"
            >
              {{ $t('transaction.details.title_dest')[form_trans.tran_type] }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="form_trans.fund_out_channel"
                    :label="$t('transaction.details.fund_out_channel.label')"
                    data-cy="fund_out_channel_textfield"
                  />
                </v-col>
                <v-col cols="6">
                  <CountrySelector
                    v-model="form_trans.country_to"
                    :label="$t('transaction.details.country_to.label')"
                    data-cy="fundout_country"
                  />
                </v-col>
                <v-col cols="5">
                  <InputCurrency
                    v-model="form_trans.currency_to"
                    :label="$t('transaction.details.currency_to.label')"
                    data-cy="fundout_currency"
                  />
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    :label="$t('transaction.details.amount_to.label')"
                    v-model="form_trans.amount_to"
                    clearable
                    @keyup="(event) => {
                      const match = this.form_trans.amount_to.match(/^\d+\.?(\d{0,2})?/)
                      this.form_trans.amount_to = match ? match[0] : ''
                    }"
                    :hint="$t('transaction.details.notify.label')"
                    :error="$v.form_trans.amount_to.$error"
                    :error-messages="!$v.form_trans.amount_to.required ? $t('validate.required', { field: $t('transaction.details.amount_to.label') }) : ''"
                    data-cy="fundout_amount"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card class="ma-1">
        <v-card-title
          data-cy="client_screen_title"
        >
          {{ $t('transaction.details.title_screen') }}
          <v-spacer />
          <v-btn
            v-show="form_alert_locked"
            color="error"
            @click="dialog_unlock_confirmation = true"
            data-cy="alert_unlock_btn"
          >
            {{ $t('alert.details.buttons.unlock') }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <template v-if="alertCase">
                  <tr>
                    <td class="px-0">
                      {{ $t('transaction.details.alert_case_id.label') }}
                    </td>
                    <td>{{ alertCase.id }}</td>
                  </tr>
                  <tr>
                    <td class="px-0">
                      {{ $t('transaction.details.alert_case_status.label') }}
                    </td>
                    <td>{{ $t('alert_case.table.header.status.options')[alertCase.status] }}</td>
                  </tr>
                  <tr>
                    <td
                      class="px-0"
                      width="200px;"
                    >
                      {{ $t('transaction.details.view_alert_case.label') }}
                    </td>
                    <td>
                      <v-btn
                        color="primary"
                        text
                        @click="() => {
                          dialog_alert_case_detail = true
                          dialog_alert_case_id = alertCase.id
                        }"
                      >
                        <v-icon left>
                          mdi-open-in-new
                        </v-icon>
                        {{ $t('transaction.details.view_alert_case.label') }}
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td
                    class="px-0"
                    width="200px;"
                  >
                    {{ $t('transaction.details.hit.label') }}
                  </td>
                  <td :class="records.alert ? 'red--text': 'green--text'">
                    <b>{{ records.alert ? $t('transaction.details.hit.options.yes') : $t('transaction.details.hit.options.no') }}</b>
                  </td>
                </tr>
                <tr>
                  <td class="px-0">
                    {{ $t('screen.single.risk_level.label') }}
                  </td>
                  <td>{{ records.alert && records.alert.hit_nationality ? records.alert.risk_level : $t('transaction.details.risk_level.na') }}</td>
                </tr>
                <tr>
                  <td class="px-0">
                    {{ $t('screen.single.risk_score.label') }}
                  </td>
                  <td>{{ records.alert && records.alert.hit_nationality ? records.alert.risk_score : $t('transaction.details.risk_level.na') }}</td>
                </tr>
                <tr v-if="records.alert">
                  <td class="px-0">
                    {{ $t('alert.details.buttons.view_details') }}
                  </td>
                  <td class="pa-2">
                    <!-- <p>{{ results.service_encrypt }}</p> -->
                    <v-btn
                      :href="records.alert && records.alert.link"
                      target="_blank"
                      small
                      text
                      color="primary"
                      data-cy="view_screening_detail_btn"
                    >
                      <v-icon left>
                        mdi-open-in-new
                      </v-icon>
                      {{ $t('alert.details.buttons.view_details') }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider v-show="records.alert" />
        </v-card-text>
        <v-card-text v-if="records.alert">
          <v-row>
            <v-col cols="12">
              <v-select
                :label="$t('alert.details.form.hit_status.label')"
                v-model="form_alert.hit_status"
                dense
                :disabled="form_alert_locked"
                :items="hit_status_options"
              >
                <template
                  v-slot:selection="items"
                >
                  <div
                    data-cy="selected_hit_status"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
                <template
                  v-slot:item="items"
                >
                  <div
                    :data-cy="`hit_status_${items.item.value}`"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('alert.details.form.exposition.label')"
                v-model="form_alert.exposition"
                :value-comparator="(a, b) => parseInt(a) == parseInt(b)"
                dense
                :disabled="form_alert_locked"
                :error="$v.form_alert.exposition.$error"
                :error-messages="$v.form_alert.exposition.$error ? $t('validate.required', { field: $t('alert.details.form.exposition.label') }) : ''"
                :items="exposition_options(form_alert.hit_status)"
              >
                <template
                  v-slot:selection="items"
                >
                  <div
                    data-cy="selected_exposition"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
                <template
                  v-slot:item="items"
                >
                  <div
                    :data-cy="`exposition_${items.item.value}`"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                    :disabled="form_alert_locked"
                    data-cy="alert_suggestion_btn"
                  >
                    {{ $t('alert.details.buttons.suggestions') }}
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(remarks, key) in suggested_remarks"
                    @click="form_alert.remarks += (form_alert.remarks ? '\n' : '') + remarks"
                    :key="key"
                  >
                    <v-list-item-title
                      :data-cy="`suggested_remarks_${key}`"
                    >
                      {{ remarks }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-textarea
                :label="$t('alert.details.form.remarks.label')"
                counter
                auto-grow
                rows="1"
                row-height="15"
                v-model="form_alert.remarks"
                :disabled="form_alert_locked"
                :error="$v.form_alert.remarks.$error"
                :error-messages="$v.form_alert.remarks.$error ? $t('validate.required', { field: $t('alert.details.form.remarks.label') }) : ''"
                data-cy="alert_remark_textarea"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-dialog
          v-model="dialog_unlock_confirmation"
          persistent
          max-width="350"
        >
          <v-card>
            <v-card-title>{{ $t('dialogs.alert_unlock.title') }}</v-card-title>
            <v-card-text>{{ $t('dialogs.alert_unlock.text') }}</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error darken-1"
                text
                @click="form_alert_locked = false; dialog_unlock_confirmation = false;"
              >
                {{ $t('dialogs.alert_unlock.buttons.yes') }}
              </v-btn>
              <v-btn
                color="darken-1"
                text
                @click="dialog_unlock_confirmation = false"
              >
                {{ $t('dialogs.alert_unlock.buttons.no') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog_alert_case_detail"
        >
          <AlertCaseDetailsContent :case-id="dialog_alert_case_id" />
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'

import InputCurrency from '@/components/forms/InputCurrency'
import CountrySelector from '@/components/forms/CountrySelector'
import InputDate from '@/components/forms/InputDate'
import { mapGetters } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import AlertCaseDetailsContent from '../forms/AlertCaseDetailsContent'

const customCurrencyValidation = (value) => String(value).match(/^\d+\.?(?:\d+)?$/) != null

const form = {
  form_trans: () => ({
    tran_date: (new Date()).getTime(),
    tran_type: '',
    amount: '0.00',
    currency: 'HKD',
    fund_in_channel: '',
    country: '',
    amount_to: '0.00',
    currency_to: 'USD',
    fund_out_channel: '',
    country_to: '',
    source_of_fund: '',
    purpose: '',
    tran_ref: '',
    counterparty_nationality: '',
    recipient: '',
    remitter: '',
    remarks: ''
  }),
  form_alert: () => ({
    hit_status: 0,
    exposition: '',
    remarks: ''
  })
}

export default {
  components: {
    InputCurrency,
    InputDate,
    CountrySelector,
    DateFormat,
    AlertCaseDetailsContent
  },
  props: {
    records: {
      type: Object,
      default: null
    }
  },
  watch: {
    records: {
      deep: true,
      handler (new_records) {
        this.recordsToForm(new_records)
      }
    },
    form_alert: {
      deep: true,
      handler () {
        // exposition
        for (var i = 0; i < this.exposition_options(this.form_alert.hit_status).length; i++) {
          if (String(this.form_alert.exposition) === String(this.exposition_options(this.form_alert.hit_status)[i].value)) {
            return
          }
        }
        // CLEAR
        this.form_alert.exposition = ''
      }
    }
  },
  computed: {
    ...mapGetters('alerts', [
      'hit_status_options',
      'exposition_options',
      'suggested_remarks'
    ]),
    tran_type_color () {
      switch (this.form_trans.tran_type) {
        case 'forex':
          return 'pink lighten-5'
        case 'inward':
          return 'deep-purple lighten-5'
        case 'outward':
          return 'indigo lighten-5'
        case 'loan':
          return 'blue lighten-5'
        default:
          return ''
      }
    }
  },
  data () {
    return {
      form_trans: form.form_trans(),
      form_alert: form.form_alert(),
      form_alert_locked: false,
      dialog_unlock_confirmation: false,
      requestLoading: false,
      alertCase: null,
      dialog_alert_case_detail: false,
      dialog_alert_case_id: null
    }
  },
  validations () {
    return {
      // form: {
      // 	names: {
      // 		$each: {
      // 			sur: {
      // 				required: requiredIf(() => !this.form.names[0].other)
      // 			},
      // 			other: {
      // 				required: requiredIf(() => !this.form.names[0].sur)
      // 			},
      // 		}
      // 	},
      // 	// name: {
      // 	// 	required
      // 	// }
      // },
      form_alert: {
        exposition: {
          required: requiredIf(() => this.form_alert.hit_status === 1 || this.form_alert.hit_status === 2)
        },
        remarks: {
          required: requiredIf(() => this.form_alert.hit_status === 1 || this.form_alert.hit_status === 2)
        }
      },
      form_trans: {
        amount: {
          required,
          customCurrencyValidation
        },
        amount_to: {
          required,
          customCurrencyValidation
        },
        tran_date: {
          required
        },
        tran_type: {
          required
        }
      }
    }
  },
  methods: {
    onSave () {
      this.$v.form_alert.$touch()
      this.$v.form_trans.$touch()

      if (this.$v.form_alert.$invalid || this.$v.form_trans.$invalid || !this.$refs.tran_date.correctDate) { return }

      if (this.records.alert) {
        this.form_trans.alert = Object.assign(this.form_trans.alert, this.form_alert)
      }

      this.requestLoading = true
      this.$store.dispatch('transaction/save', this.form_trans).then((results) => {
        // this.requestLoading = false;
        if (results.status === 200) {
          this.recordsToForm(results.data)

          // this.prepareForm();
          this.$notify({
            type: 'success',
            title: this.$t('transaction.details.msg.save.title'),
            text: this.$t('transaction.details.msg.save.success')
          })
          this.$emit('onSave', results.data)

          // vm.$modal.hide(this.$parent.name);
          // vm.$refs.table.getData()
          // vm.updateAlertInfo();
        } else {
          this.$notify({
            type: 'error',
            title: this.$t('transaction.details.msg.save.title'),
            text: this.$t('transaction.details.msg.save.error')
          })
        }
      }, (error) => {
        console.log(error)
        // this.requestLoading = false;
        this.$notify({
          type: 'error',
          title: this.$t('transaction.details.msg.save.title'),
          text: this.$t('transaction.details.msg.save.exception')
        })
      })
        .finally(() => {
          this.requestLoading = false
        })
    },
    async recordsToForm (records) {
      this.form_alert_locked = records && records.alert && records.alert.hit_status !== 0
      this.form_trans = Object.assign(this.form_trans, records || {})
      this.form_alert = Object.assign(this.form_alert, records ? (records.alert || {}) : {})
      this.$store.dispatch('risk/get_risk_info', (this.records.alert === null ? '' : this.records.alert.hit_nationality)).then((results) => {
        if (results && results.status === 200) {
          // $store.dispatch('risk/get_risk_info', 'Hong Kong')
          this.records.alert.risk_level = results.data.risk_level || this.$t('company.details.form.risk_level.options.Not In List')
          this.records.alert.risk_score = results.data.risk_score || 0
        }
      })
      this.alertCase = records.alert_case
      // if (!records.alert_case) {
      //   this.$store.dispatch('alert_case/findWhere', {
      //     populate: 'company,person',
      //     where: {
      //       [this.records.person ? 'person' : 'company']: this.records.person.id || this.records.company.id
      //     }
      //   }).then(({ data }) => {
      //     if (data[0] && data[0].create_date >= this.records.create_date) {
      //       this.alertCase = data[0]
      //     }
      //   })
      // }
    }
  },
  mounted () {
    this.recordsToForm(this.records)
  }
}
</script>

<style>

</style>
