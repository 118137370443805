<template>
  <v-form
    id="pop-up"
    @submit.prevent="onSave"
  >
    <v-app-bar
      class="white pa-0"
      elevate-on-scroll
      dense
      scroll-target="#scrolling-tran-edit"
      height="60"
      style="z-index: 3;"
    >
      <v-toolbar-title
        class="pl-4"
        data-cy="tran_detail_title"
      >
        {{ $t('transaction.details.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-show="records && records.tran_type != 'loan'"
        outlined
        depressed
        class="mr-3"
        :href="'/v2/msoform/generate/' + (records ? records.id : 'none')"
        target="_blank"
        data-cy="tran_detail_download_btn"
      >
        Download
      </v-btn>
      <v-btn
        outlined
        depressed
        @click.prevent="$refs.tran_detail.onSave()"
        color="primary"
        class="mr-3"
        data-cy="tran_detail_save_btn"
      >
        {{ $t('alert.details.buttons.save') }}
      </v-btn>
      <PopupDelete
        v-slot:default="{ on, loading }"
        @yes="(done) => {
          $store.dispatch('transaction/remove', records.id)
            .then(() => {
              this.$emit('onSave', null);
            })
            .catch((error) => { console.warn(error) })
            .finally(done); // notify popup stop loading and close
        }"
        data-cy="tran_detail"
      >
        <v-btn
          outlined
          depressed
          color="error"
          class="mr-3"
          v-on="on"
          :loading="loading"
          data-cy="tran_detail_delete_btn"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          {{ $t('person.details.buttons.delete') }}
        </v-btn>
      </PopupDelete>
    </v-app-bar>
    <v-card-text
      style="max-height: calc(90vh - 80px);"
      id="scrolling-tran-edit"
      class="overflow-y-auto"
    >
      <v-container fluid>
        <TransactionDetail
          ref="tran_detail"
          :records.sync="records"
          @onSave="onSave"
        />
      </v-container>
    </v-card-text>
  </v-form>
</template>
<script type="text/javascript">
// import Vue from 'vue'
// import Vuelidate from 'vuelidate'
import 'vue-slider-component/theme/antd.css'
import TransactionDetail from '@/components/forms/TransactionDetail2'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'

// Vue.use(Vuelidate)

export default {
  components: {
    TransactionDetail,
    PopupDelete
  },
  props: {
    records: {
      type: Object,
      default: null
    }
  },
  computed: {

  },
  data () {
    return {}
  },
  validations () {
    return {
    }
  },
  methods: {
    onSave (saved_data) {
      this.$emit('onSave', saved_data)
    }
  },
  mounted () {

  }
}
</script>
<style type="text/css" scoped>
/*.v--modal {
	max-height: 90vh;
    top: 5vh !important;
    overflow: auto !important;
}
.v--modal-overlay {
	z-index: 9999;
}*/
/* #pop-up {
    height: 92vh;
    overflow: auto;
} */
.content {
	max-height: calc(92vh - 49px);
    overflow: auto;
    box-sizing: border-box;
}
textarea {resize: vertical;}
.remark_dropdown ul {box-shadow: 0 0 3px 0px;outline: none;white-space: normal;max-width: 100%;z-index: 10001;}
.remark_dropdown ul>li>a {white-space: normal;word-break: break-all;}
</style>
